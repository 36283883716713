window.addEventListener('heyflow-init', (event) => {

    const popUpSection = document
    .querySelector('footer')
    .querySelector('.link-list-block')
    .querySelector('.link-list');

    while (popUpSection.firstChild) {
        popUpSection.removeChild(popUpSection.firstChild);
    }
    const buttonTnCs = document.createElement('button');
    buttonTnCs.innerText = 'Terms and Conditions';
    buttonTnCs.style.fontFamily = 'Inter';
    buttonTnCs.style.fontSize = '13px';
    buttonTnCs.style.fontWeight = 'normal';
    buttonTnCs.style.color = '#bababb';
    buttonTnCs.style.textDecoration = 'none';
    buttonTnCs.addEventListener('mouseenter', () => {
        buttonTnCs.style.color = '#255daa';
    });
    buttonTnCs.addEventListener('mouseleave', () => {
        buttonTnCs.style.color = '#bababb';
    });
    buttonTnCs.setAttribute('onclick', 'showTnCs()');

    const buttonAdDisclosure = buttonTnCs.cloneNode();
    buttonAdDisclosure.innerText = 'Ad Disclosure';
    buttonAdDisclosure.setAttribute('onclick', 'showAdDisclosure()');
    buttonAdDisclosure.addEventListener('mouseenter', () => {
        buttonAdDisclosure.style.color = '#255daa';
    });
    buttonAdDisclosure.addEventListener('mouseleave', () => {
        buttonAdDisclosure.style.color = '#bababb';
    });

    const buttonPrivacyPolicy = buttonTnCs.cloneNode();
    buttonPrivacyPolicy.innerText = 'Privacy Policy';
    buttonPrivacyPolicy.setAttribute('onclick', 'showPrivacyPolicy()');
    buttonPrivacyPolicy.addEventListener('mouseenter', () => {
        buttonPrivacyPolicy.style.color = '#255daa';
    });
    buttonPrivacyPolicy.addEventListener('mouseleave', () => {
        buttonPrivacyPolicy.style.color = '#bababb';
    });

    popUpSection.appendChild(buttonTnCs);
    popUpSection.appendChild(buttonAdDisclosure);
    popUpSection.appendChild(buttonPrivacyPolicy);
});